import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";

import "./App.css";

import LinearProgress from "@material-ui/core/LinearProgress";

// import GetInByEmail from "./essential/GetInByEmail";

const GetInByEmail = lazy(() => import("./essential/GetInByEmail"));
const GetInByPhone = lazy(() => import("./essential/GetInByPhone"));
const LoginByPhone = lazy(() => import("./essential/LoginByPhone"));
const LoginByEmail = lazy(() => import("./essential/LoginByEmail"));
const UserRegistration = lazy(() => import("./essential/UserRegistration"));

// import { TheLayout } from "./essential/index";
const TheLayout = lazy(() => import("./essential/TheLayout"));
const LandingPage = lazy(() => import("./essential/LandingPage"));
// import { TheLayout } from "./essential";

function App() {
  // console.clear();
  // console.log(process.env.REACT_APP_url);
  // console.log(process.env.REACT_APP_port);
  return (
    <div className="App">
      <Suspense fallback={<LinearProgress />}>
        <Switch>
          <Route
            exact
            path="/loginByEmail"
            name="Login By Email"
            component={GetInByEmail}
          />
          <Route
            exact
            path="/loginByPhone"
            name="Login By Phone"
            component={GetInByPhone}
          />
          {/* <Route
            exact
            path="/loginByPhone"
            name="Login By Phone"
            component={LoginByPhone}
          />
           <Route
            exact
            path="/loginByEmail"
            name="Login By Email"
            component={LoginByEmail}
          /> */}

          <Route
            exact
            path="/LandingPage"
            name="Landing Page"
            component={LandingPage}
          />

          <Route
            exact
            path="/UserRegistration"
            name="Registration page"
            component={UserRegistration}
          />

          <Route path="/" name="Home" component={TheLayout} />
        </Switch>
      </Suspense>
    </div>
  );
}

export default App;
